import React, { useReducer } from "react";
import { Modal, Theme, CloseIcon } from "@informed-innovations/components";
import styled from "styled-components";
import { MailSlideshow } from "./MailSlideshow";
import PropTypes from "prop-types";

const ModalBody = styled.div`
  padding: 2rem;
  color: ${Theme.colors.black};
  h2 {
    text-align: center;
  }
  @media screen and (max-width: 960px) {
    padding: 2rem 20px;
  }
`;

const XIconContainer = styled.div`
  position: absolute;
  transform: scale(1.5);
  margin: 0;
  top: 35px;
  right: 43px;
  cursor: pointer;
  @media screen and (max-width: 960px) {
    top: 20px;
    right: 30px;
  }
`;

const SlideshowContainer = styled.div`
  max-width: 1100px;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const MailModal = ({ setShowMailModal, data, toggleDigital, forceRerender }) => {
  return (
    <Modal maxWidth={1100} onClose={() => setShowMailModal(false)} showCloseButton={false}>
      <XIconContainer onClick={() => setShowMailModal(false)}>
        <CloseIcon />
      </XIconContainer>
      <ModalBody>
        <SlideshowContainer>
          <MailSlideshow data={data} toggleDigital={toggleDigital} />
        </SlideshowContainer>
      </ModalBody>
    </Modal>
  );
};

MailModal.propTypes = {
  setShowMailModal: PropTypes.func,
  data: PropTypes.object,
};
