import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Card from "../shared/Card";
import { GlobeIcon, Theme } from "@informed-innovations/components";
import { MagnifyingGlassIcon } from "../../common/components/icons/MagnifyingGlassIcon";
import { NoImageIcon } from "../../common/components/icons/NoImageIcon";
import { AuthSecureImage } from "../../common/components/AuthSecureImage";
import PropTypes from "prop-types";
import { ToggleSwitch } from "./ToggleSwitch";

const CardContainer = styled.div`
  padding-top: 0px;
  background-color: ${Theme.colors.gray[100]};
  border-radius: 6px;
  position: relative;
  ${(props) =>
    !props.image &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
`;

const CardTitle = styled.div`
  line-height: normal;
  font-size: 18px;
  color: ${Theme.colors.black};
  white-space: break-spaces;
  height: 32px;
  &:focus-visible {
    outline: 3px solid ${Theme.colors.cyan};
    transition: outline 0.15s ease;
  }
  b {
    color: ${Theme.colors.blue};
  }
  display: flex;

  @media screen and (max-width: 525px) {
    height: 45px;
    font-size: 14px;
    padding-top: 8px;
    overflow: hidden;
  }
`;

const CardTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0;
`;

const RepImageToggleWrapper = styled.div`
  display: flex;
`;

const CardImageContainer = styled.div`
  margin: auto;
  text-align: center;
  padding: 30px;

  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 960px) {
    padding-top: 10px;
  }

  @media screen and (max-width: 525px) {
    width: 100%;
    max-height: 100%;
    padding: 15px;
  }
`;

const CardImage = styled.img`
  object-fit: cover;
  object-position: center center;
  width: 100%;
  content: "text";
`;

const RideAlongImageContainer = styled.div`
  /* margin-top: 30px; */
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 20px;
  width: 399px;
  background-color: white;
  display: flex;
  img {
    max-width: 200px;
  }
  span {
    white-space: nowrap;
    padding-left: 20px;
  }

  @media screen and (max-width: 960px) {
    span {
      font-size: 16px;
    }
    svg {
      width: 25px;
    }
    width: 80%;
  }

  @media screen and (max-width: 525px) {
    display: block;
    margin-left: 20px;
    width: 85%;
    span {
      padding-left: 0;
    }
    svg {
    }
  }
  @media screen and (max-width: 460px) {
    img {
      padding-bottom: 20px;
    }
  }
`;

const DesktopGlobe = styled(GlobeIcon)`
  width: 25px;
  margin-left: 10px;
  display: inline;
  margin-right: 10px;
  @media screen and (max-width: 460px) {
    margin-left: 0px;
    margin-right: 5px;
  }
`;

const MobileGlobe = styled(GlobeIcon)`
  width: 25px;
  display: none;
  @media screen and (max-width: 460px) {
  }
`;

const ImageWrapper = styled.div`
  background-color: rgba(51, 51, 102);
  width: 100%;
  position: relative;
`;

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  color: black;
  padding: 30px;
  text-align: center;
`;

const EnlargeOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 102, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 30px;
  padding-top: 40px;
  text-align: center;
  cursor: pointer;
  div {
    display: block;
    margin: auto;
    opacity: 1;
    color: ${Theme.colors.white};
  }
  p {
    padding-top: 10px;
  }

  svg {
    width: 50px;
    height: 50px;
  }
`;

const ToggleStateText = styled.span`
  color: ${Theme.colors.black};
  ${(props) =>
    props.showDigital &&
    css`
      font-weight: bold;
    `}
`;

const ToggleButton = styled(ToggleSwitch)`
  width: 20px !important;
  max-width: 20px !important;
`;

const repImageType = (mail) => {
  if (mail.image && mail.campaign?.replacementImage) {
    return "both";
  } else if (mail.image) {
    return "imageOnly";
  } else if (mail.campaign?.replacementImage) {
    return "repOnly";
  } else {
    return "none";
  }
};

const generateRepresentativeImage = (
  mail,
  setShowEnlarge,
  setShowMailModal,
  onImageClick,
  generateImage,
  generateMultipleImages,
  showEnlarge
) => {
  //TODO: check revision works when site is back up
  const imageType = repImageType(mail);
  const mailImage = () => {
    if (imageType === "both") {
      return {
        digital: mail.campaign.replacementImage.links[0].href,
        scan: mail.image.links[0].href,
      };
    }
    if (imageType === "repOnly") {
      return mail.campaign.replacementImage.links[0].href;
    }
    if (imageType === "imageOnly") {
      return mail.image.links[0].href;
    }
    return "";
  };

  const repImage = mailImage();

  const handleKeyPress = (e, type) => {
    if (e.code === "Space" || e.code === "Enter") {
      setShowMailModal(true);
      onImageClick();
    }
  };

  return (
    <CardImageContainer tabIndex={0}>
      <div
        tabIndex={0}
        onFocus={() => setShowEnlarge(true)}
        onBlur={() => setShowEnlarge(false)}
        onKeyDown={(e) => handleKeyPress(e)}
      >
        {/* {mailImage.includes("image-no-mailpiece") && imageMissing === false ? (
                <NoImage>
                  <NoImageIcon />
                  <p>
                    There is one or more mailpiece(s) for which we do not currently have an image that is included in
                    today's mail.
                  </p>
                </NoImage>
              ) : ( */}
        <ImageWrapper
          onMouseEnter={() => setShowEnlarge(true)}
          onMouseLeave={() => setShowEnlarge(false)}
          onClick={() => {
            setShowMailModal(true);
            onImageClick();
          }}
          onFocus={() => setShowEnlarge(true)}
          onBlur={() => setShowEnlarge(false)}
          tabindex={0}
        >
          {imageType === "both" && generateMultipleImages(repImage)}
          {imageType !== "both" && generateImage(repImage)}
          {showEnlarge && (
            <EnlargeOverlay tabIndex={0}>
              <div>
                <MagnifyingGlassIcon />
                <p>Enlarge</p>
              </div>
            </EnlargeOverlay>
          )}
        </ImageWrapper>
        {/* )} */}
      </div>
    </CardImageContainer>
  );
};

const generateRideAlongImage = (mail) => {
  return (
    <RideAlongImageContainer>
      <a href={mail.campaign.rideAlong.links[1].href} target="_blank" rel="noreferrer" alt="Ride-Along Image Link">
        <AuthSecureImage imageData={mail.campaign.rideAlong.links[0].href} component={CardImage} alt={"alt"} />
      </a>
      <span>
        <DesktopGlobe />
        <a href={mail.campaign.links[0].href} target="_blank" rel="noreferrer" alt="Ride-Along Image Link">
          Learn More
        </a>
      </span>
    </RideAlongImageContainer>
  );
};

export const DesignCard = ({ mail, setShowMailModal, onImageClick, imageMissing, toggleDigital }) => {
  const imageRef = useRef();
  const imageContain = useRef();
  const [showEnlarge, setShowEnlarge] = useState(false);

  //  mail.campaign?.replacementImage ? mail.campaign.replacementImage.links[0].href : mail.image ? mail.image.links[0].href : "";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeContent = useCallback(() => {
    if (!imageRef.current) return;
    if (imageContain.current.getBoundingClientRect().height === 300) return;
    imageRef.current.style.height = imageContain.current.getBoundingClientRect().height + `px`;
  });

  useEffect(() => {
    resizeContent();
  }, [resizeContent]);

  const generateImage = (repImage) => {
    return <AuthSecureImage imageData={repImage} component={CardImage} alt={"Mail image"} />;
  };

  const generateMultipleImages = (repImage) => {
    if (mail.showDigital) {
      return <CardImage src={repImage.digital} />;
    } else {
      return <CardImage src={repImage.scan} />;
    }
  };

  // mail.image = scan
  // mail.campaign.replacement = digital

  const imageType = repImageType(mail);

  return (
    <CardContainer key={mail.id} image={!!mail.image || !!mail.campaign} ref={imageContain}>
      <Card>
        {" "}
        {mail.campaign && mail.campaign.brandDisplayName && (
          <CardTitleWrapper>
            <CardTitle>
              <b>From: </b>
              {mail.campaign.brandDisplayName}
            </CardTitle>
            {imageType == "both" && (
              <RepImageToggleWrapper>
                <ToggleStateText showDigital={!mail.showDigital} style={{ paddingRight: "8px" }}>
                  Scan
                </ToggleStateText>
                <ToggleSwitch
                  defaultChecked={mail.showDigital}
                  onLabel={" "}
                  offLabel={" "}
                  onClick={() => toggleDigital(mail)}
                />
                <ToggleStateText showDigital={mail.showDigital}>Digital</ToggleStateText>
              </RepImageToggleWrapper>
            )}
          </CardTitleWrapper>
        )}
        {mail &&
          generateRepresentativeImage(
            mail,
            setShowEnlarge,
            setShowMailModal,
            onImageClick,
            generateImage,
            generateMultipleImages,
            showEnlarge
          )}
        {mail.campaign?.rideAlong && generateRideAlongImage(mail)}
      </Card>
    </CardContainer>
  );
};

DesignCard.propTypes = {
  mail: PropTypes.object,
  setShowMailModal: PropTypes.func,
  onImageClick: PropTypes.func,
  imageMissing: PropTypes.bool,
};
