import React from "react";
import styled from "styled-components";
import { Theme } from "@informed-innovations/components";
import PropTypes from "prop-types";
const VisibileRing = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -2px;
  bottom: 0;
`;
const Container = styled.label`
  position: relative;
  input:focus-visible ~ ${VisibileRing} {
    outline: 3px solid ${Theme.colors.cyan};
  }
  span {
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    vertical-align: top;
  }
`;

const Toggle = styled.div`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 32px;
`;

const Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 10px;
  bottom: 0;
  background-color: ${Theme.colors.gray[400]};
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 3px;
    background-color: ${Theme.colors.white};
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const ToggleLabel = styled.span`
  position: absolute;
  font-size: 16px;
  font-family: Verdana, sans-serif;
  user-select: none;
  transform: translate(-50%, -50%);
  top: 45%;
`;

const OnLabel = styled(ToggleLabel)`
  color: ${Theme.colors.white};
  display: none;
  left: 35%;
  top: 50%;
`;

const OffLabel = styled(ToggleLabel)`
  color: ${Theme.colors.black};
  top: 50%;
  right: 0;
  left: 60%;
`;

const Input = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked + ${Toggle} > ${Slider} {
    background-color: ${Theme.colors.blue};

    &:before {
      transform: translateX(20px);
    }

    ${OnLabel} {
      display: block;
    }

    ${OffLabel} {
      display: none;
    }
  }
`;

export const ToggleSwitch = React.forwardRef(({ value, children, onLabel, offLabel, ...props }, ref) => {
  return (
    <Container>
      <Input value={value} {...props} ref={ref} />
      <Toggle>
        <Slider>
          <OnLabel>{onLabel ? onLabel : "YES"}</OnLabel>
          <OffLabel>{offLabel ? offLabel : "NO"}</OffLabel>
        </Slider>
      </Toggle>
      <VisibileRing />
      <span>{children}</span>
    </Container>
  );
});

ToggleSwitch.propTypes = {
  /**
   * Input value
   */
  value: PropTypes.string,
  /**
   * The label to display when switch is toggled 'on'.
   */
  onLabel: PropTypes.string,
  /**
   * The label to display when switch is toggled 'off'.
   */
  offLabel: PropTypes.string,
  /**
   * Children nodes or label.
   */
  children: PropTypes.node,
};
