import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Theme, GlobeIcon } from "@informed-innovations/components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { DownCarrat } from "@informed-innovations/components";
import { AuthSecureImage } from "../../common/components/AuthSecureImage";
import PropTypes from "prop-types";

const Container = styled.div`
  max-width: 85%;
  margin: 0 auto;
  .carousel .slide {
    display: block;
    margin: auto 0;
    width: 100%;
  }
  .carousel-status {
    font-size: 16px;
    width: 100%;
    text-align: center;
    text-shadow: none;
    color: black;
    top: 92%;
    bottom: 0;

    @media screen and (max-width: 525px) {
      top: 88%;
    }
  }
`;

const Slide = styled.div`
  padding-bottom: 90px;
  max-width: 880px;
  text-align: left;
  position: relative;
  z-index: 0;

  img {
    max-width: 875px;
  }

  @media screen and (max-width: 500px) {
    padding-bottom: 50px;
  }
`;

const LeftArrow = styled.div`
  margin: auto;
  svg {
    &:hover {
      cursor: pointer;
    }
    transform: rotate(90deg) scale(2);
  }
  @media screen and (max-width: 960px) {
    margin-right: 40px;
  }

  @media screen and (max-width: 755px) {
    margin-bottom: 40%;
  }

  @media screen and (max-width: 525px) {
    margin-bottom: 36%;
    margin-right: 10px;
  }
`;

const RightArrow = styled.div`
  margin: auto;
  svg {
    &:hover {
      cursor: pointer;
    }
    transform: rotate(-90deg) scale(2);
  }

  @media screen and (max-width: 960px) {
    margin-left: 40px;
  }

  @media screen and (max-width: 755px) {
    margin-bottom: 40%;
  }

  @media screen and (max-width: 525px) {
    margin-bottom: 36%;
    margin-left: 10px;
  }
`;

const CardImageContainer = styled.div`
  margin: 0 auto;
  display: block;
  text-align: center;
  max-width: 60%;

  @media screen and (max-width: 525px) {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
  }
`;

const MailImage = styled.img`
  max-width: 50%;
`;

const Status = styled.span`
  b {
    padding: 0 10px;
  }
`;

const RideAlongImageContainer = styled.div`
  /* margin-top: 30px; */
  margin: 0 auto;
  padding: 20px 0;
  /* width: 399px; */
  max-width: 60%;
  background-color: white;
  display: flex;
  img {
    max-width: 200px;
  }
  span {
    padding-left: 20px;
  }

  @media screen and (max-width: 960px) {
    span {
      font-size: 16px;
    }
    svg {
      width: 25px;
    }
    width: 80%;
  }

  @media screen and (max-width: 520px) {
    width: 100%;
    display: block;
  }
  @media screen and (max-width: 460px) {
    img {
      padding-bottom: 20px;
    }
  }
`;

const DesktopGlobe = styled(GlobeIcon)`
  width: 25px;
  margin-left: 10px;
  display: inline;
  @media screen and (max-width: 460px) {
    display: none;
  }
`;

const MobileGlobe = styled(GlobeIcon)`
  width: 25px;
  display: none;
  @media screen and (max-width: 460px) {
    display: inline;
    margin-left: -15px;
    margin-right: 5px;
  }
`;

export const MailSlideshow = ({ data }) => {
  const [slideState, setSlideState] = useState(data.startingSlide);

  const next = () => {
    setSlideState(slideState + 1);
  };

  const prev = () => {
    setSlideState(slideState - 1);
  };

  const updatedCurrentSlide = (index) => {
    if (slideState !== index) {
      setSlideState(index);
    }
  };

  const renderImage = (item) => {
    if (item.toggleRepImage) {
      return (
        <div>
          {item.showDigital && (
            <AuthSecureImage
              imageData={item.campaign.replacementImage.links[0].href}
              component={MailImage}
              alt={"Digital campaign image"}
            />
          )}{" "}
          {!item.showDigital && (
            <AuthSecureImage imageData={item.image.links[0].href} component={MailImage} alt={"Scanned mail image"} />
          )}
        </div>
      );
    } else {
      const mailImage = item.image ? item.image.links[0].href : item.campaign.replacementImage.links[0].href;
      if (mailImage.includes("image-no-mailpiece")) {
        return <MailImage src={mailImage} alt={"No mail image"} />;
      } else {
        return <AuthSecureImage imageData={mailImage} component={MailImage} alt={"Image of your mail"} />;
      }
    }
  };

  return (
    <>
      <LeftArrow>
        <DownCarrat onClick={() => prev()} />
      </LeftArrow>
      <Container>
        <Carousel
          // below statement is caught by sonarlint but is required syntax for component
          statusFormatter={(current, total) => (
            <Status>
              <b>{current}</b> of <b>{total}</b>
            </Status>
          )}
          showThumbs={false}
          showStatus={true}
          showArrows={false}
          selectedItem={slideState}
          autoPlay={false}
          interval={5000}
          infiniteLoop={true}
          onChange={updatedCurrentSlide}
          showIndicators={false}
        >
          {Object.values(data.data).map((item) => {
            console.log(item);
            if (item.image || item.campaign) {
              return (
                <Slide key={item.imb}>
                  <CardImageContainer>{renderImage(item)}</CardImageContainer>
                  {item.campaign?.rideAlong && (
                    <RideAlongImageContainer>
                      <AuthSecureImage
                        imageData={item.campaign.rideAlong.links[0].href}
                        component={MailImage}
                        alt={"Scanned mail image"}
                      />
                      <span>
                        <MobileGlobe />
                        <a
                          href={item.campaign.links[0].href}
                          target="_blank"
                          rel="noreferrer"
                          alt="Ride-Along Image Link"
                        >
                          Learn More
                        </a>
                        <DesktopGlobe />
                      </span>
                    </RideAlongImageContainer>
                  )}
                </Slide>
              );
            }
            return null;
          })}
        </Carousel>
      </Container>
      <RightArrow>
        <DownCarrat onClick={() => next()} />
      </RightArrow>
    </>
  );
};

MailSlideshow.propTypes = {
  data: PropTypes.object,
};
